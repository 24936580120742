.subcontainer_bar {
  width: 1298px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.issue_txt {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px;
}

.left_side_box_style {
  display: flex;
  width: 404px;
  border: 1px solid #f5f6f8;
  border-radius: 10px;
}

.active_left_side_box_style {
  display: flex;
  width: 404px;
  border: 1px solid #f5f6f8;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px #00000026;
}

.card_txt_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_id {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  padding: 3px;
  min-width: 200px;
  cursor: pointer;
  flex-grow: 1;
}

.due_in {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #c5003e;
  padding: 5px;
  flex-grow: 0;
}

.date {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
}

.other_detail_1 {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  color: #868e96;
  flex-grow: 1;
}

.other_detail_2 {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  color: #868e96;
  flex-grow: 0;
}

.other_detail_val {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  color: #0d0d0e;
  word-wrap: break-word;
  text-transform: capitalize;
}

.flag_delay_con {
  display: flex;
}

.active_bar {
  width: 5px;
  border-radius: 10px;
  background-color: #d11243;
}

.card_content {
  padding: 16px 16px 16px 10px;
  width: 397px;
}

.left_sub_container {
  margin-bottom: 16px;
  height: 100%;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.issue_priority_high {
  width: auto;
  height: 27px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  background-color: #f9e6ec;
  color: #c5003e;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
}

.issue_priority_medium {
  width: auto;
  height: 27px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  background-color: #fae5c9;
  color: #f48f1c;
  padding: 5px;
}

.issue_priority_low {
  width: auto;
  height: 27px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  color: #fab005;
  background-color: #fff9db;
  padding: 5px;
}

.delayed_by_high {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  color: #c5003e;
  padding: 5px;
}

.delayed_by_medium {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  color: #e67700;
  padding: 5px;
}

.delayed_by_low {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  color: #fab005;
  padding: 5px;
}
