.popper_display {
  z-index: 5;
}

.logout_txt {
  width: 110px;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 25px 0px #00000040;
}
.header_container {
  display: grid;
  grid-template-columns: 4% 1fr 29%;
}
.logo_container {
  align-self: center;
  cursor: pointer;
}
.user_container {
  justify-self: end;
  display: flex;
  align-items: stretch;
}
.user_wrapper {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .profile_icon svg .profile:hover {
    fill: #c92a2a;
  }
}

.paper_effect {
  // width: 1440px;
  height: 64px;
  padding: 5px 24px 16px 24px;
  justify-content: space-between;
  box-shadow: 0px 1px 4px 0px #0000001a;
}
.heading_txt {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #0d0d0e;
  margin-top: 12px;
}
.licious_logo_style {
  margin-right: 10px;
}
.down_arrow {
  margin-left: 10px;
}

.bm_btn {
  background-color: #c92a2a;
  width: 150px;
  height: 35px;
  text-transform: capitalize;
  margin: 8px 20px 0px 0px;
}
