.sidebar_container {
  padding: 32px;
  max-height: 95vh;
  overflow-y: scroll;
}

.filter_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212529;
}
.sidebar_filter_list_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    /* Gray/06 */
    color: #868e96;
  }
}
.focus {
  cursor: pointer;
}

.title_wrapper {
  &:not(:first-child) {
    padding-top: 20px;
  }

  display: flex;
  flex-direction: column;
  gap: 15px;
}
.sidebar_title {
  display: flex;
  flex-direction: column;
  & > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}
.sidebar_title_wrapper {
  padding-bottom: 20px;
}
