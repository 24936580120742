.chips {
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #dee2e6;
  border-radius: 50px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.paragraph {
  color: #212529;
  font-size: 14px;
  font-weight: 400;
}
.chips_container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chips_wrapper {
  align-items: center;
  display: flex;
  gap: 16px;
}
