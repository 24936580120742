input[type='checkbox'] {
  visibility: hidden;
}
.customise-label {
  position: relative;
  display: 'block';
}
.measure-title {
  margin: 8px;
  color: #868e96;
}
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  height: 23px;
  width: 23px;
  border: 1px solid black;
  background-color: white;
  outline: none;
}
.customise-label input:checked ~ .geekmark {
  background-color: #c92a2a;
  border: none;
}
.geekmark:after {
  content: '';
  position: absolute;
  display: none;
}
.customise-label input:checked ~ .geekmark:after {
  display: block;
}
.customise-label .geekmark:after {
  left: 8px;
  bottom: 5px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filterTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212529;
}

.customiseCancelButton {
  width: 120px;
  height: 51px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  margin: 10px;
  color: #343a40;
  cursor: pointer;
}

.customiseApplyButton {
  width: 120px;
  height: 51px;
  background: #c92a2a;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
}
.parentTwoCustomiseContainer {
  display: grid;
  height: 250px;
}
.childOneCustomiseContainer {
  margin-bottom: 5px;
}
.childTwoCustomiseContainer {
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow-y: scroll;
}
.childTwoCustomiseCheckbox {
  padding: 5px;
}
.parentOneCustomiseContainer {
  font-size: 14px;
  line-height: 24px;
  display: grid;
}

.headerCustomiseContainer {
  display: flex;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.headerCustomiseTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.headerCustomiseRightMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 auto;
}

.headerCustomiseReset {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
  color: #5399fa;
  cursor: pointer;
}

.columnTitle {
  display: inline-block;
  margin-left: 10px;
}
