.sidebar {
  width: 72px;
  // height: 92vh;
  color: #fff;
  background-color: #464646;
  display: flex;

  .activeItem {
    color: #fff;
    width: 5px;
    background-color: #c5efcc;
  }
}

.non_selected_icon {
  color: #000;
}
.selected_icon {
  color: #fff;
}

.list {
  padding: 0px;
}

.tab_name {
  font-family: 'Satoshi';
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  width: 70px;
  padding: 0px 0px 0px 8px;
}

.sidebar_box {
  background-color: transparent;
  width: 72px;
}

.active_sidebar_box {
  background-color: #ffffff1a;
  width: 72px;
}

.icon_css {
  padding: 10px 8px 0px 8px;
}
