.dt_main_container {
  background-color: #f5f6f8;
  width: 100%;
}

.bm_component {
  margin: 14px 0px 24px 20px;
}

.bm_main_container {
  width: 99%;
  height: 182px;
  padding: 16px 24px 16px 24px;
  gap: 16px;
  opacity: 0px;
  background-color: #ffffff;
  border-radius: 20px;
}

.breach_details {
  display: flex;
  padding: 12px;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
}

.content_divider {
  border: 1px solid #ced4da;
}

.vertical_divider {
  border-left: 1px solid #ced4da;
  height: 50px;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.breach_key_css {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: left;
}

.breach_value_css {
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 700;
  line-height: 35.84px;
  text-align: left;
}

.total_breaches {
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 700;
  line-height: 35.84px;
  text-align: left;
  color: #c5003e;
}

.positive_breaches {
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 700;
  line-height: 35.84px;
  text-align: left;
  color: #2b8a3e;
}

//graph component
.gc_first_block {
  width: 648px;
  background-color: #ffffff;
  border-radius: 24px;
  margin: 0px 10px 0px 10px;
}
.gc_flex_property {
  display: flex;
  // justify-content: space-evenly;
}

.first_block_top_card {
  width: 600p;
  padding: 20px 20px 0px 20px;
}

.city_hub_wise_block {
  width: 299px;
  height: 56px;
  border: 1px solid #c0c1c3;
  border-radius: 12px;
}
.city_wise_block {
  border-radius: 12px 0px 0px 12px;
  width: 149px;
  cursor: pointer;
}
.city_wise_block_clicked {
  border-radius: 12px 0px 0px 12px;
  width: 149px;
  background-color: #f5f5fa;
  color: #5c57ea;
  font-weight: 700px;
  cursor: pointer;
}

.hub_wise_block {
  border-radius: 12px 0px 0px 12px;
  width: 149px;
  cursor: pointer;
}
.hub_wise_block_clicked {
  border-radius: 0px 12px 12px 0px;
  width: 149px;
  background-color: #f5f5fa;
  color: #5c57ea;
  font-weight: 700px;
  cursor: pointer;
}

.block_padding {
  padding: 16px;
}
.icon_padding {
  padding-right: 16px;
}
.bar_graph_style {
  width: auto;
  height: 385px;
  padding: 24px;
  overflow-x: scroll;
}

.day_week_month_block {
  width: 261px;
  height: 54px;
  border: 1px solid #c0c1c3;
  border-radius: 12px;
  margin-top: 20px;
}
.dwm_padding {
  padding: 10px;
  padding-top: 15px;
  width: 87px;
  text-align: center;
}
.dwm_wise_block {
  border-radius: 12px 0px 0px 12px;
  width: 87px;
}
.block_txt {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
}
.flex_prop {
  display: flex;
  justify-content: space-between;
}

.left_block {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
}

.center_blocks {
  flex: 2;
  justify-content: center;
  align-items: center;
  display: flex;
}

.right_block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}
.flex {
  display: flex;
}
.top_card_no_data {
  height: 100px;
  text-align: center;
  padding-top: 50px;
  font-size: 17px;
  font-weight: 700;
}
.checkbox_filter_container {
  display: flex;
  margin: 20px 20px;
  justify-content: space-between;
}

.resolved_breaches_txt {
  margin-top: 16px;
  font-family: 'Satoshi';
  font-weight: 500;
  font-size: 16px;
}
.graph_component {
  height: 600px;
}

.barGraphContainer {
  height: 600;
  width: 100%;
  overflow-x: auto;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.div1,
.div2 {
  flex: 1 1 100%;
  min-width: 200px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .div1,
  .div2 {
    flex: 1 1 calc(50% - 20px);
  }
}
@media (min-width: 1024px) {
  .div1,
  .div2 {
    flex: 1 1 calc(50% - 20px);
  }
}
.yAxisContainer {
  overflow-x: scroll;
}

.line_graph_container {
  background-color: #ffffff;
  border-radius: 24px;
  margin: 20px 10px 10px 10px;
}
.hourly_trend_title {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}
.city_hub_date_filter {
  display: flex;
}
.hourly_trend_txt {
  padding: 20px 0px 0px 10px;
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: left;
}
.filter_flex {
  display: flex;
  gap: 5px;
}
.filtr_width {
  width: 147px;
  height: 50px;
  border-color: #5c57ea;
}

.filtr_width_disabled {
  position: relative;
  border-radius: 10px;
  height: 48px;
}

.filtr_width_disabled::before {
  font-family: 'Satoshi';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.filtr_width_disabled:hover::before {
  opacity: 1;
}

.issue_type_txt {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 700;
  line-height: 25.6px;
  text-align: left;
  color: #464646;
  margin: 4px 0px 0px 20px;
  min-width: 100px;
}
.divider_header {
  margin-top: 10px;
  margin-left: 20px;
}
.issue_type_card {
  border-radius: 24px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  color: #464646;
  font-weight: 700;
  font-size: 14.22px;
  font-family: 'Satoshi';
  padding: 8px 10px 8px 10px;
  margin-left: 20px;
  cursor: pointer;
}
.active_issue_type_card {
  border: 1px solid #5c57ea;
  color: #5c57ea;
}
.flex_property {
  width: 1360px;
  display: flex;
  padding: 20px 0px 10px 0px;
  overflow-y: scroll;
}
