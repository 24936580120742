.column_date {
  min-width: 100px;
}
.column_date_time {
  min-width: 170px;
}
.container {
  display: grid;
  position: relative;
}
.main_container {
  grid-template-columns: 20% 1fr;
}
.less_container {
  grid-template-columns: 2% 1fr;
}
.left_container {
  background-color: #f8f9fa;
  overflow: auto;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.table_title_container {
  display: flex;
  gap: 8px;
}

.title_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: inline;
}
.right_container {
  padding: 16px 40px;
  padding-top: 0;
  gap: 32px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
.filter_button {
  cursor: pointer;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  line-height: 0%;
}
.right_align_items {
  align-self: end;
  display: flex;
  gap: 16px;
}
.padding_8 {
  padding: 8px 0px;
}
.margin_32_px {
  background-color: #f8f9fa;
}
.flex_container {
  display: flex;
}
.flex_1 {
  flex: 1;
}
.link {
  color: #1c7ed6;
  cursor: pointer;
  margin-left: 4px;
}

.rule_list_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #868e96;
}

.search_box_container {
  margin-top: 10px;
}

.job_config_detail {
  ////margin-top: 10px;
  //max-width: 500px;
  //align-self: end;
  //display: flex;
  //padding: 8px 0px;
  ////gap: 16px;
  align-self: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 0px;
  margin-top: -80px;
  max-width: 600px;
  margin-right: 300px;
}

/* Add this CSS class to your stylesheet */
.job_config_detail_link {
  color: #007bff; /* Default link color */
  transition: color 0.3s ease-in-out; /* Smooth color transition */
  text-decoration: underline; /* Underline the link by default */
  cursor: pointer;
}

.job_config_detail_link:hover {
  color: #0056b3; /* Change to a slightly darker blue on hover */
}

.job_config_details_container {
  display: flex; /* Display children elements in a row */
  justify-content: space-between; /* Space out the children elements */
  align-items: center; /* Center vertically */
}

.job_config_container {
  display: flex;
  justify-content: space-between; /* Space out the children elements */
  align-items: center; /* Center vertically */
}

.job_config_section {
  display: flex;
  gap: 20px; /* Adjust this gap value as needed */
  align-items: center; /* Align items vertically within the section */
  /* Additional styles for job_config_section */
}

.job_config_select {
  margin-top: 20px;
  max-width: 600px;
  min-width: 400px;
}

.rightmost_section {
  margin-top: 50px;
  display: flex;
  gap: 20px; /* Adjust this gap value as needed */
  /* Additional styles for rightmost_section */
}
