.modal_icon {
  cursor: inherit;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.cross_btn {
  text-align: end;
  margin: 10px;
}

.container {
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 676px;
  overflow: scroll;
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 60px 80px 60px 80px;
}

.modal_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Satoshi';
}

.modal_title {
  font-size: 28px;
  line-height: 35.84px;
  color: #212529;
  text-align: center;
  font-family: 'Satoshi';
  font-weight: 500;
  word-break: break-all;
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
}

.sve_cncl_container {
  margin-left: 237px;
  margin-bottom: 20px;
}

.save_btn {
  background-color: #c5003e;
  color: #fff;
}

.save_btn:hover {
  background-color: #c5003e;
  color: #fff;
}

.cancel_btn {
  background-color: #fff;
  color: #000;
  margin-left: 20px;
}

.cancel_btn:hover {
  color: #000;
  background-color: #fff;
}
.ok_btn {
  width: 86px;
  height: 52px;
  font-size: 16px;
  border-radius: 12px;
  margin-top: 30px;
  font-family: 'Satoshi';
  background-color: #c5003e;
  border: none;
  color: #fff;
  font-weight: 500;
}
.cancel_btn {
  width: 112px;
  height: 52px;
  border-radius: 12px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  color: #495057;
  font-family: 'Satoshi';
  font-weight: 400;
  font-size: 16px;
  margin-top: 30px;
}
.icon_css {
  text-align: center;
  padding-bottom: 25px;
}
.modal_btns_align {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 25px;
}
