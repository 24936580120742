.App {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../../public/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../../public/fonts/Satoshi-Variable.woff') format('woff'),
    url('../../public/fonts/Satoshi-Variable.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
}
