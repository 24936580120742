.column_date {
  min-width: 100px;
}
.column_date_time {
  min-width: 170px;
}
.container {
  display: grid;
  position: relative;
}
.main_container {
  grid-template-columns: 20% 1fr;
}
.less_container {
  grid-template-columns: 2% 1fr;
}
.left_container {
  background-color: #f8f9fa;
  overflow: auto;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.table_title_container {
  display: flex;
  gap: 8px;
}

.title_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: inline;
}
.right_container {
  padding: 16px 40px;
  padding-top: 0;
  gap: 32px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
.filter_button {
  cursor: pointer;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  line-height: 0%;
}
.right_align_items {
  align-self: end;
  display: flex;
  gap: 16px;
}
.padding_8 {
  padding: 8px 0px;
}
.margin_32_px {
  background-color: #f8f9fa;
}
.flex_container {
  display: flex;
}
.flex_1 {
  flex: 1;
}
.link {
  color: #1c7ed6;
  cursor: pointer;
  margin-left: 4px;
}

.rule_list_select {
  margin-top: 20px;
  max-width: 600px;
}

.rule_list_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #868e96;
}

.search_box_container {
  margin-top: 10px;
}
