/* TimePicker.css */

.time_picker_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #4a4a4a;
}

.time_picker {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.time_picker_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-right: 8px;
  padding: 12px;
  border: 1px solid #c0c1c3;
  border-radius: 12px;
  width: 55px;
  height: 45px;
}

.time_picker_input input {
  width: 40px;
  text-align: center;
  font-size: 18px;
  border: none;
  outline: none;
}

.time_picker_input span {
  font-size: 12px;
  color: #9b9b9b;
}

.time_picker_period {
  padding: 8px;
  border: 1px solid #c0c1c3;
  border-radius: 12px;
  margin-bottom: 17px;
  width: 75px;
  height: 47px;
  padding-top: 12px;
  text-align: center;
  font-family: 'Satoshi';
  margin-left: 20px;
}

.time_picker_period select {
  font-size: 18px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.colon {
  font-size: 35px;
  margin-right: 8px;
  margin-left: 8px;
  color: #000000;
  margin-bottom: 22px;
}
.element_heading {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  margin-top: 20px;
  color: #464646;
}
.input_val {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
}
.box_placeholder {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
}
