.card {
  display: inline-block;
  margin: 0 32px 0 0;
  width: 250px;
  height: 76px;
  padding: 23px 0px 20px 15px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  cursor: pointer;

  &.selected {
    background: #ffffff;
    border: 1px solid #d11243;
  }
}

.card_label {
  font-family: 'Satoshi';
  color: #808387;
  font-weight: 500;
  line-height: 21.6px;
  font-size: 16px;
}

.card_value {
  color: #464646;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.84px;
  margin-left: 20px;
  margin-top: -7px;
}
