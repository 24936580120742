.input_root {
  padding: 0px 50px 0px 0px !important; // used important to override mui styles
  & > input {
    padding-left: 14px !important;
  }
}

.end_adornment_root {
  right: 22px !important; // used important to override mui styles
}
