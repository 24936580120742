.popper_display {
  z-index: 5;
}
.black_ribbon {
  width: 100%;
  background-color: black;
  padding: 10px;
}
.header_container {
  padding: 0 16px;
  display: grid;
  grid-template-columns: 10% 1fr 20%;
}
.logo_container {
  align-self: center;
  cursor: pointer;
}
.user_container {
  justify-self: end;
  padding: 10px 0;
  display: flex;
  align-items: stretch;
}
.user_wrapper {
  cursor: pointer;
  /* padding-left: 2rem; */
  display: flex;
  align-items: center;
  gap: 16px;
  .profile_icon svg .profile:hover {
    fill: #c92a2a;
  }
}
.logout_popper_container {
  width: 200px;
  background: white;
  border: 1px solid #e5e5e5;
}

.bm_btn {
  background-color: #c92a2a;
  width: 150px;
  height: 35px;
  text-transform: capitalize;
  margin: 8px 20px 0px 0px;
}
