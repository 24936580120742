.column_date {
  min-width: 100px;
}
.column_date_time {
  min-width: 170px;
}
.container {
  display: grid;
  position: relative;
}
.main_container {
  display: grid;
  position: relative;
  grid-template-columns: min(300px) 1fr;
  gap: 16px;
}
.delay_container {
  display: grid;
  position: relative;
  grid-template-columns: 0% 1fr;
  gap: 16px;
}
.less_container {
  grid-template-columns: 2% 1fr;
}
.left_container {
  background-color: #e9ecef;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0px 4px 103px rgba(50, 50, 71, 0.01),
    0px 4px 59px rgba(50, 50, 71, 0.06);
  border-radius: 8px;
}
.left_container_1 {
  background-color: #e9ecef;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0px 4px 103px rgba(50, 50, 71, 0.01),
    0px 4px 59px rgba(50, 50, 71, 0.06);
  border-radius: 8px;
  max-height: 600px;
}

.title_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.last_update_at {
  font-size: 12px;
  color: #808387;
}

.title {
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: inline;
}
.table_container {
  padding-top: 0;
  overflow-x: auto;
}
.right_container {
  padding: 16px 40px;
  padding-top: 0;
  gap: 32px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}
.filter_button {
  cursor: pointer;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  line-height: 0%;
}
.right_align_items {
  align-self: end;
  display: flex;
  align-items: center;
  gap: 16px;
}
.padding_8 {
  padding: 8px 0px;
}
.margin_32_px {
  margin-top: 32px;
}

.tab_container {
  background-color: #f8f9fa;
}
.flex_container {
  display: flex;
  align-items: center;
}
.flex_1 {
  flex: 1;
}
.link {
  color: #1c7ed6;
  cursor: pointer;
  margin-left: 4px;
}
.a1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 16px;
  flex-direction: column;
  font-size: 12px;
  & > div {
    text-align: center;
    margin-top: 2px;
  }
}
.b1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.a2 {
  width: 250px;
  color: black;
  padding: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.b2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.card_container {
  width: 200;
  height: 100;
  background: #f59f00;
  border-radius: 8px;
  display: flex;
}
.card_container_1 {
  width: 200;
  height: 100;
  background: white;
  border-radius: 8px;
  display: flex;
}

.sla_details_spinner {
  height: 400px;
}

.table_title_container {
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content 200px 200px;
  grid-gap: 30px;
}

.sidebar_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
