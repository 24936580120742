.input_root {
  height: 40px;
  padding: 0px 20px 0px 10px !important; // to override mui style
}

.autocomplete_popper {
  inset: 12px auto auto 0px !important; // to override mui style
  box-shadow: 0px 0px 24px #00000040;
  border-radius: 12px;
  width: auto !important;
}

.autocomplete_paper {
  border-radius: 12px;
  padding: 12px;
}

.table_filter_popper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  max-height: 300px;
  overflow: auto;
}

.checkbox_root {
  padding: 0px;
}

.filter_apply_btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.filter_apply_btn {
  text-transform: capitalize;
  padding: 10px 30px;
  border-radius: 8px;
}
.count_circle {
  padding: 1px 7px;
  min-width: 24px;
  background-color: #495057;
  border-radius: 50%;
  display: inline-block;
  color: white;
  margin-left: 5px;
}

.ul_list {
  min-height: 250px;
  height: 250px;
}
