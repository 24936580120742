.custom_input {
  & :global(.MuiOutlinedInput-notchedOutline) {
    border-color: #a7abb6;
    border-radius: 8px;
  }
  & :global(.MuiInputBase-root) {
    height: 48px;
  }

  & :global(.MuiInputBase-multiline) {
    height: auto;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.input {
  & :global(.MuiOutlinedInput-input) {
    width: 78%;
  }
}

.edit_btn {
  padding: 10px 2px !important; // To override custom button styles
  border-radius: 0 !important; // To override custom button styles
  color: #1c7ed6;

  &:hover {
    background: none;
  }
}

.modal_btn {
  background: #f1f3f5;
  border-radius: 6px;
}

.apply_border {
  border: 1px solid #212529;
}
