.loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  transform: translate(-50%, -50%);
  border: 8px solid #868e96;
  border-top: 8px solid #c92a2a;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
