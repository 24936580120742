.login_form_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form_container {
  padding: 4rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 500px;
}
.login_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_header_text_wrapper {
  display: flex;
  flex-direction: column;
}

.login_button_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .MuiFormHelperText-root {
    font-size: 14px;
  }
}
