.column_date {
  min-width: 100px;
}
.column_date_time {
  min-width: 170px;
}

.pagination_next_button {
  border: 1px solid #f1f3f5;
  border-radius: 8px;
  padding: 0px;
}

.pagination_back_button {
  border: 1px solid #f1f3f5;
  border-radius: 8px;
  padding: 0px;
  margin-right: 5px;
}
.main_container {
  padding: 10px;
  background-color: #f8f9fa;
}
.table_container {
  max-height: 800px;
  padding-top: 0;
  overflow: scroll;
  background-color: #f8f9fa;
  box-sizing: border-box;
  text-align: left;

  thead > tr {
    background-color: #f8f9fa;
    text-align: left;
    position: sticky;
    top: -1px;
  }
  th {
    padding: 10px;
    color: #868e96;
    background-color: #f8f9fa;
    font-weight: 600;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.02em;
    min-width: 120px;
  }
  tr {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #f8f9fa;
  }
  td {
    padding: 10px;
    color: #4b4f54;
    background-color: transparent;
    white-space: break-spaces;
    font-weight: 400;
    line-height: 14px;
    font-size: 12px;
    letter-spacing: 0.02em;
    min-width: 100px;
  }
}

.spinner {
  height: 100px;
}

.centerAlign {
  text-align: center;
}

.padding_verticle_0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.even_row {
  background-color: #f5f5f5;
  & > td {
    background-color: #f5f5f5;
  }
}

.odd_row {
  background-color: #fff;
  & > td {
    background-color: #fff;
  }
}

.green_cell {
  background-color: #99cc33 !important;
  color: #ffffff !important;
}

.red_cell {
  background-color: #ff9966 !important;
  color: #ffffff !important;
}

.yellow_cell {
  background-color: #ffcc00 !important;
  color: #ffffff !important;
}

.sticky_column {
  position: sticky;
  z-index: 1;
}
.link {
  color: blue;
  font-size: 12px;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.link:hover {
  text-decoration: underline;
}

.red_color_cell {
  background-color: #fb4242 !important;
  color: #ffffff !important;
}

.delayTagCenterAlign {
  text-align: center !important;
}
