.dashboard_container {
  width: 100%;
}

.active_alert_container {
  height: 750px;
  background-color: #f5f6f8;
  border: 1px solid #f5f6f8;
}

.tab_card_container {
  margin-left: 20px;
  white-space: nowrap;
  position: relative;
}

.scroll_icon_css {
  position: absolute;
  top: 66px;
  left: 98%;
  transform: translateX(-42%);
  cursor: pointer;
}

.card_details {
  display: flex;
  flex-direction: column;
}

.delivery_status_tabs {
  margin-top: 10px;
  column-gap: 30px;
  justify-content: space-between;

  & .tab {
    .spinner_container {
      width: 50px;
      height: 50px;
    }
  }
}

.filter_style {
  display: flex;
  justify-content: flex-end;
  margin: 8px;
  gap: 10px;
}

.label_icon_container {
  display: flex;
  gap: 15px;
}

.actionable_container {
  height: 624px;
  margin: 10px 16px 10px 16px;
  border-radius: 20px;
  // gap: 24px;
  background-color: #ffffff;
  // overflow: hidden;
  // position: relative;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.subcontainer_bar {
  // width: 1298px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  // position: fixed;
}

.issue_txt {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px;
  margin-right: 50px;
}

.card_and_details_container {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 27px;
  height: 1123px;
  margin: 20px;
}

.right_container {
  height: 510px;
  overflow-y: scroll;
}

.right_side_box1_style {
  min-height: 82px;
  width: 870px;
  border: 1px solid #f5f6f8;
  padding: 16px;
  border-radius: 10px;
  overflow: scroll;
}

.right_side_box2_style {
  width: 870px;
  padding: 13px 12px 13px 12px;
  border-radius: 10px;
  border: 1px solid #f5f6f8;
  margin-top: 20px;
  background-color: #faf7f2;
}

.card_txt_align {
  display: flex;
  justify-content: space-between;
}

.card_id {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 3px;
}

.due_in {
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #c5003e;
  padding: 5px;
}

.alert_flag {
  width: 50px;
  height: 27px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  gap: 10px;
  background-color: #f9e6ec;
  color: #c5003e;
  padding: 5px;
}

.delayed_by {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 700;
  color: #c5003e;
  padding: 5px;
}

.date {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
}

.other_detail {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  color: #868e96;
  padding: 5px;
}

.other_detail_val {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  color: #0d0d0e;
  padding: 5px;
}

.flag_delay_con {
  display: flex;
}

.main_container {
  display: grid;
  grid-template-columns: 32% 68%;
  gap: 16px;
  // margin: 20px;
  padding: 0px 10px 0px 10px;
}

.data_card_component_scroll {
  max-height: 461px;
  overflow-y: auto;
}

.card_pagination {
  position: sticky;
  transform: translate(-6%, -9%);
}

.pagination_internal_css {
  font-size: 14px;
  font-family: 'Satoshi';
}

.detail_content {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.content_divider {
  border: 1px solid #dee2e6;
  height: 50px;
}

.content_key {
  border: 1px solid #808387;
  color: #808387;
  padding: 3px;
  border-radius: 4px;
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.content_key_de {
  border: 1px solid #808387;
  color: #808387;
  padding: 3px;
  border-radius: 4px;
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 7px;
  margin-top: -10px;
}

.content_value {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  color: #343a40;
}

//suggestion action box
.suggestion_action_box {
  width: 870px;
  padding: 24px 12px 24px 12px;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resolved_alert_css {
  font-family: 'Satoshi';
  font-size: 16px;
  color: rgb(15, 162, 15);
  font-weight: 500;
}

.suggestion_action_txt {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 700;
  color: #4d4d4f;
  cursor: pointer;
}

.flex_css {
  display: flex;
  justify-content: space-between;
}

.resolve_btn {
  width: 109px;
  height: 44px;
  border-radius: 12px;
  background-color: #d11243;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Satoshi';
  text-transform: capitalize;
  margin-right: 20px;
  border: none;
}

.suggested_action_container {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0px 15px;
}

.suggested_action_header {
  font-family: 'Satoshi';
  font-size: 12px;
  font-weight: 500;
  color: #808387;
  border: none;
  text-align: center;
  text-transform: capitalize;
}

.suggested_action_row {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-align: center;
  word-break: break-all;
}

.send_alert_btn {
  width: 120px;
  height: 44px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #d11243;
  color: #d11243;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Satoshi';
  text-transform: capitalize;
}

.suggested_action_table_container {
  max-height: 250px;
  overflow: scroll;
  box-shadow: none;
}

.suggested_action_table_header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px;
}

.suggested_action_confirm_btn {
  width: 152px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  color: #d11243;
  text-transform: capitalize;
}

.diabled_suggested_action_confirm_btn {
  width: 152px;
  height: 32px;
  background-color: #c0c1c3;
  border-radius: 6px;
  border: 1px solid #c0c1c3;
  color: #ffffff;
  text-transform: capitalize;
}

.table_container {
  border-radius: 12px;
  border: 1px solid #ced4da;
  box-shadow: none;
  margin-top: 15px;
  max-height: 250px;
  overflow-x: auto;
}

.table_header {
  background-color: #f8f8f8;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table_cell {
  font-family: 'Satoshi';
  border-bottom: none;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 14px;
  color: #464646;
  word-wrap: break-word;
}

.table_row {
  font-family: 'Satoshi';
  border-bottom: none;
  font-weight: 500;
  padding: 10px 0px 10px 0px;
  font-size: 14px;
  max-width: 100px;
  word-wrap: break-word;
}

.modification_detail_box {
  border: 1px solid #dee2e6;
  width: 870px;
  border-radius: 10px;
  padding: 13px 12px 13px 12px;
  background-color: #faf7f2;
  margin-top: 20px;
}

.slot_css {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  width: 84px;
  height: 44px;
  padding-top: 10px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #464646;
  margin: 0px 8px 20px 10px;
  cursor: pointer;
}

.active_slot {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  width: 84px;
  height: 44px;
  padding-top: 10px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #464646;
  margin: 0px 8px 20px 10px;
  background-color: #eeeeee;
  cursor: pointer;
}

.modification_txt {
  font-family: 'Satoshi';
  font-size: 20px;
  font-weight: 700;
  color: #4d4d4f;
}

.no_data_txt {
  font-family: 'Satoshi';
  font-size: 28px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin-top: 50px;
  color: #4d4d4f;
  margin-top: 120px;
}

.no_data_subtxt {
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #4d4d4f;
  margin-top: 26px;
}

.custom_select {
  width: 130px;
  height: 54px;
  padding: 12px 16px 10px 16px;
}

.no_action_trail_data {
  font-family: 'Satoshi';
  margin-top: 15px 0px 15px 0px;
  text-align: center;
}

.refresh_btn {
  width: 86px;
  height: 52px;
  font-size: 16px;
  border-radius: 12px;
  margin-top: 30px;
  font-family: 'Satoshi';
  background-color: #c5003e;
  border: none;
  color: #fff;
  font-weight: 500;
}

.refresh_btn_con {
  text-align: center;
}

.card_no_data {
  padding: 35px;
  font-family: 'Satoshi';
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.select_css {
  width: 160px;
  height: 45px;
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  border: 1px solid #c0c1c3;
  font-family: 'Satoshi';
}

.detail_unavailable {
  height: 82px;
  width: 870px;
  border: 1px solid #d11243;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  color: #d11243;
  font-family: 'Satoshi';
  font-size: 16px;
  font-weight: 500;
}

.custom_style_spinner {
  top: calc(0% - 200px);
  background-color: transparent;
}

.lm_table_row {
  font-family: 'Satoshi';
  border-bottom: none;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 14px;
}

.refresh_box {
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 9px 12px 32px 12px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }
}

.view_log_btn {
  color: #d11243;
  font-family: 'Satoshi';
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.view_log_btn:hover {
  background-color: #f0f0f0;
  padding: 0px 8px 0px 8px;
  border-radius: 7px;
}

.view_log_details {
  height: auto;
}

.view_log_headers {
  color: #808387;
  font-family: 'Satoshi';
  font-weight: 500;
  font-size: 12px;
  border-bottom: none;
}

.view_log_details_container {
  padding: 16px, 48px, 16px, 8px;
  background-color: #fff9db;
}

.view_log_details_td {
  border-bottom: none;
}

.impacted_trip_container {
  display: 'flex';
  justify-content: 'space-between';
  gap: '8px';
}

.impacted_trip_container_no_flex {
  padding: 2px;
}

.filtr_width {
  width: 200px;
  height: 50px;
}

.filter_flex {
  display: flex;
  gap: 5px;
}

.degrade_service {
  background-color: transparent;
  color: #d11243;
  font-size: 14px;
  font-family: 'Satoshi';
  font-weight: 700;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  width: 179px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #d11243;
    color: #ffffff;
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #a1a1a1;
    border-color: #e0e0e0;
    cursor: not-allowed;
  }
}

input[type='checkbox'] {
  visibility: visible; /* Ensure checkboxes are visible */
  width: 20px; /* Width of the checkbox */
  height: 20px; /* Height of the checkbox */
  margin-right: 18px; /* Space between checkbox and label */
  appearance: none; /* Remove default checkbox styling */
  border: 2px solid #5c57ea; /* Border color */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor on hover */
  min-width: 20px; /* Prevent the checkbox from shrinking */
  max-width: 20px; /* Prevent the checkbox from stretching */
  box-sizing: border-box; /* Ensure padding/border are included in the width/height */
}

input[type='checkbox']:checked {
  background-color: #5c57ea; /* Background color when checked */
  border: 2px solid #5c57ea; /* Maintain border color when checked */
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
}

input[type='checkbox']:checked::after {
  content: ''; /* Create a pseudo-element for the tick */
  display: block;
  width: 5px; /* Width of the tick */
  height: 10px; /* Height of the tick */
  border: solid white; /* Tick color */
  border-width: 0 2px 2px 0; /* Tick shape */
  transform: rotate(45deg); /* Rotate to form a tick */
  position: relative;
  left: 4px; /* Adjust position */
  top: 1px; /* Adjust position */
}
