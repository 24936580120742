.custom_dialog {
  height: 383px !important;
  max-width: none !important;
  border-radius: 25px;
}
.select_width {
  width: 603px;
  margin-top: 10px;
}
.modal_heading {
  margin-top: 10px;
  font-family: 'Satoshi';
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
  color: #464646;
}
.modal_sub_heading {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
}
.time_slect_container {
  display: flex;
  justify-content: space-between;
}
.reason_select_width {
  width: 304px;
  margin-top: 10px;
}
.reason_dropdown_name {
  font-family: 'Satoshi';
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
  margin-top: 20px;
}
.cancel_btn_css {
  font-family: 'Satoshi';
  font-size: 14.22px;
  font-weight: 700;
  line-height: 19.91px;
  border: 1px solid #eeeeee;
  color: #5c57ea;
  text-transform: capitalize;
  width: 100px;
  padding: 10px;
  border-radius: 12px;
}
.next_btn_css {
  font-family: 'Satoshi';
  font-size: 14.22px;
  font-weight: 700;
  line-height: 19.91px;
  border: 1px solid #eeeeee;
  background-color: #5c57ea;
  text-transform: capitalize;
  width: 100px;
  padding: 10px;
  border-radius: 12px;
  color: #ffffff;
  margin-left: 20px;
}
.next_btn_css:disabled {
  background-color: #cccccc; /* Gray background */
  border-color: #bbbbbb; /* Lighter border color */
  color: #888888; /* Text color for disabled state */
  cursor: not-allowed; /* Change cursor to 'not-allowed' */
  opacity: 0.7; /* Slight transparency to indicate disabled state */
}
.next_btn_css:hover {
  background-color: #5c57ea;
  color: #ffffff;
  border: 1px solid #eeeeee;
}
.modal_footer {
  text-align: center;
  padding: 22px;
}
